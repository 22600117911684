import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import PostsFilters from "../modules/posts-filters";
import MobileSwiper from "../modules/mobile-swiper";

export default class ArchivePosts extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      header: viewStorage.current.querySelector('.PageHeaderArchivePosts'),
      formFilters: viewStorage.current.querySelector('.Filters'),
    }

    this.init()
    this.initForm()
  }

  init() {
    const {
      header = null
    } = this.DOM

    if (header) this.postsFiltersModule = new PostsFilters(header)
  }

  initForm() {
    const allCategoriesCheckbox = this.DOM.formFilters.querySelectorAll('input[type=checkbox]:not([name=filters_posts_all])');
    const allPostFilterCheckbox = this.DOM.formFilters.querySelector('#filters_posts_all');

    for (let checkbox of allCategoriesCheckbox) checkbox.addEventListener('change', () => {
      this.onFormChange();
    })

    allPostFilterCheckbox .addEventListener('change', () => { this.onCheckAll()})
  }

  onCheckAll(){

    clearTimeout(this.timerDebounce);

    this.timerDebounce = setTimeout(() => {
      window.location = `?`

    }, 700);
  }

  onFormChange() {

    const allPostFilterCheckbox = this.DOM.formFilters.querySelector('#filters_posts_all');
    allPostFilterCheckbox.checked = false

    clearTimeout(this.timerDebounce);

    this.timerDebounce = setTimeout(() => {

      const checkedCategories = viewStorage.current.querySelectorAll('input[type=checkbox]:not([name=filters_posts_all]):checked')
      const categories = [...checkedCategories].map((input) => input.value);

      let queryString = '';

      for (let type of categories) {
        queryString += `&categories[]=${encodeURIComponent(type)}`;
      }

      window.location = `?${queryString}`

    }, 700);
  }

  onLeaveCompleted() {
    this.postsFiltersModule && this.postsFiltersModule.destroy()
  }
}
