import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Marquee {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.marquee = this.DOM.container.querySelector('.marquee')

    if (!this.DOM.marquee) return

    this.setMarquee()
  }

  setMarquee() {
    const { container, marquee } = this.DOM

    this.ST = new ScrollTrigger({
      trigger: container,
      start: 'top bottom',
      end: 'bottom top',
      onEnter: () => { marquee.classList.add('--playing') },
      onLeave: () => { marquee.classList.remove('--playing') },
      onEnterBack: () => { marquee.classList.add('--playing') },
      onLeaveBack: () => { marquee.classList.remove('--playing') }
    })
  }

  destroy() {
    this.ST && this.ST.kill()
  }
}
