import { isDEVMODE, globalStorage, domStorage, viewStorage } from '../_globals'
import gsap from 'gsap'
import { Observer } from 'gsap/Observer'
gsap.registerPlugin(Observer)
import NavSubmenus from './nav-submenus'


export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.toggle = this.DOM.el.querySelector('.NavToggle')
    this.DOM.navItemsWithSubmenus = this.DOM.el.querySelectorAll('.NavItem.--submenu')

    this.setEvents()
  }

  setEvents() {
    const { overlay = null } = domStorage
    const { 
      toggle = null,
      navItemsWithSubmenus = null
    } = this.DOM

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // Observer for scroll apparition
    this.setObserver()

    // Toggle
    this.toggleNav = this.toggleNav.bind(this)
    toggle && toggle.addEventListener('click', this.toggleNav)
  
    // Submenus
    if (navItemsWithSubmenus && navItemsWithSubmenus.length) this.submenus = new NavSubmenus()    

    // Overlay
    overlay && overlay.addEventListener('click', (e) => globalStorage.menuOpen && this.close())

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  setObserver() {
    const { body } = domStorage
    const { el } = this.DOM

    this.onScrollObserver = Observer.create({
      target: window,
      type: 'scroll',
      tolerance: 0,
      onUp: () => !el.classList.contains('--visible') && el.classList.add('--visible'),
      onDown: () => {
        el.classList.remove('--visible') 
        // We close all the submenus when the user scrolls (better UX)
        this.submenus && this.submenus.closeAllSubmenus()
      }
    })

    this.scrolledObserver = Observer.create({
      target: window,
      type: 'scroll',
      onChange: (self) => {
        if (window.scrollY > 20 && !body.classList.contains('--scrolled')) body.classList.add('--scrolled')
        if (window.scrollY <= 20 && body.classList.contains('--scrolled')) body.classList.remove('--scrolled')
      }
    })
  }

  toggleNav(e) {
    e && e.preventDefault()

    globalStorage.menuOpen === true ? this.close() : this.open()
  }

  open() {
    // We close all the submenus when the user scrolls (better UX)
    this.submenus && this.submenus.closeAllSubmenus()

    // Update global variable
    globalStorage.menuOpen = true

    // Update className on body
    domStorage.body.classList.add('--show-menu')

    // Stop the virtual scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.stop()
  }

  close() {
    // We close all the submenus when the user scrolls (better UX)
    this.submenus && this.submenus.closeAllSubmenus()

    // Update global variable
    globalStorage.menuOpen = false

    // Update className on body
    domStorage.body.classList.remove('--show-menu')

    // Restart the virtual scroll
    viewStorage.viewScroll.scroll && viewStorage.viewScroll.scroll.start()
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
