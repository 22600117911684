import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageCareers extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      valuesSwiper: viewStorage.current.querySelector('.Values .swiper.--mobile'),
      relatedJobOffersSwiper: viewStorage.current.querySelector('.RelatedJobOffers .swiper.--mobile'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const { 
      valuesSwiper = null,
      relatedJobOffersSwiper = null,
      relatedPostsSwiper = null
    } = this.DOM

    if (valuesSwiper) this.valuesSwiperModule = new MobileSwiper(valuesSwiper)
    if (relatedJobOffersSwiper) this.relatedJobOffersSwiperModule = new MobileSwiper(relatedJobOffersSwiper)
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new MobileSwiper(relatedPostsSwiper)
  }

  onLeaveCompleted() {
    this.valuesSwiperModule && this.valuesSwiperModule.destroy()
    this.relatedJobOffersSwiper && this.relatedJobOffersSwiper.destroy()
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
