import { domStorage } from "../_globals"
import WknPlyr from "./wkn-plyr"
import gsap from 'gsap'

export default class PopVideos {
  constructor(container, targets) {
    if (!container) return

    this.DOM = { container, targets }

    if (!this.DOM.targets && !this.DOM.targets.length) return

    this.xhr = null
    this.plyr = null
    this.openVideo = this.openVideo.bind(this)
    this.closeVideo = this.closeVideo.bind(this)

    this.defaults = { ease: 'power4.inOut', duration: 1.15 }

    this.DOM.targets.forEach(target => target.addEventListener('click', this.openVideo))
  }

  openVideo(e) {
    e && e.preventDefault()

    // 0. We need to get all the details from the target data attributes
    const videoID = e.currentTarget.getAttribute('data-video-id')
    const videoPoster = e.currentTarget.getAttribute('data-video-poster')

    // If the videoID does not exist, it means we don't need to setup the video popup
    if (!videoID) return

    // 1. We need to create the template of Plyr.io
    const template = `
      <button class="Close" type="button" aria-label="Close">
        <span class="--sr-only">Close</span>
      </button>

      <div class="plyr__container">
        <div class="plyr__video-embed" data-plyr>
          <iframe src="https://www.youtube.com/embed/${videoID}" allowfullscreen="" allowtransparency="" allow="autoplay">
          </iframe>
        </div>

        <div class="visual__container plyr__cover has--bg">   
          <picture class="visual">
            <img class="img-fluid" src="${videoPoster}">
          </picture>
        </div>

        <button class="plyr__play" aria-label="Play Video">
          <div class="Icon --play">
            <svg width="17" height="20" viewBox="0 0 17 20">
              <path d="M17,10,0,20V0Z"></path>
            </svg>
          </div>
        </button>

        <div class="plyr__placeholder"></div>
      </div>
    `

    // 2. We need to create a popup for this video
    this.DOM.XHR = document.createElement('div')
    this.DOM.XHR.id = 'XHR'
    this.DOM.XHR.innerHTML = template

    // 3. We need to append the popup to the DOM
    domStorage.body.appendChild(this.DOM.XHR)

    this.tlIn = gsap
      .timeline({
        delay: 0.5,
        defaults: this.defaults,
        onStart: () => {
        },
        onComplete: () => {
          // Event on Close button
          this.DOM.XHR.querySelector('.Close').addEventListener('click', this.closeVideo)

          // Init the Plyr
          this.plyr = new WknPlyr(this.DOM.XHR.querySelector('.plyr__container'))

          // Autoplay on Plyr
          this.plyr && setTimeout(() => this.plyr.plyr.play(), 1000)
        }
      })
      .to(this.DOM.XHR, { clipPath: 'inset(0% 0% 0% 0%)' }, 0)
      .fromTo(this.DOM.XHR.querySelector('.plyr__container'), { y: -50, scale: 1.25 }, { y: 0, scale: 1 }, 0)
      .timeScale(0.9)
  }

  closeVideo(e) {
    e && e.preventDefault()

    this.tlOut = gsap
      .timeline({
        defaults: this.defaults,
        onStart: () => {
          // Stop the video
          this.plyr && this.plyr.plyr.stop()
        },
        onComplete: () => {
          // Remove the template from the DOM
          this.DOM.XHR && this.DOM.XHR.remove()

          // Destroy Plyr
          this.plyr && this.plyr.destroy()  
        }
      })
      .to(this.DOM.XHR, { clipPath: 'inset(0% 0% 100% 0%)' }, 0)
      .to(this.DOM.XHR.querySelector('.plyr__container'), { y: 50, scale: 1.25 }, 0)
      .timeScale(0.9)
  }

  destroy() {
    this.DOM.targets && this.DOM.targets.length && this.DOM.targets.forEach(target => target.removeEventListener('click', this.openVideo))
  }
}
