import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class ProductOverlap {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.productPreviews = this.DOM.container.querySelectorAll('.ProductPreview .clip')

    if (!this.DOM.productPreviews && !this.DOM.productPreviews.length) return

    this.timelines = []

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.setScrollAnimation()
  }

  setScrollAnimation() {
    const { productPreviews } = this.DOM

    this.mm.add(this.responsiveObj, context => {
      const { isDesktop } = context.conditions

      if (isDesktop) {
        productPreviews.forEach((preview, index) => {
          const tl = gsap
            .timeline({
              defaults: { ease: 'none' },
              scrollTrigger: {
                trigger: preview,
                start: 'top top',
                pin: index === productPreviews.length - 1 ? false : true,
                pinSpacing: false,
                scrub: true
              }
            })

            if (index !== productPreviews.length - 1) tl.to(preview, { scale: 0.95, yPercent: 5 }, 0)

            this.timelines.push(tl)
        })
      } else {
        this.timelines && this.timelines.length && this.timelines.forEach(tl => tl.kill())
      }
    })
  }

  destroy() {
    this.timelines && this.timelines.length && this.timelines.forEach(tl => tl.kill())
  }
}
