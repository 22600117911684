import { isDEVMODE, viewStorage } from '../_globals'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class viewPrllx {

  constructor(container = viewStorage.current) {

    this.DOM = {
      elems: container.querySelectorAll('[data-prllxfrom]')
    }

    if (this.DOM.elems.length === 0) return

    viewStorage.hasPrllx = true

    this.timelines = null

    this.isHorizontal = false
    this.defaultStart = this.isHorizontal ? 'left right' : 'top bottom'
    this.defaultEnd = this.isHorizontal ? 'right left' : 'bottom top'
    this.init()

  }

  init() {
    this.timelines = []

    ScrollTrigger.defaults({
      // markers: isDEVMODE
    })

    // Create PrllxElement
    this.DOM.elems.forEach((el) => {

      const { parentNode } = el
      const prllxStart = el.dataset.prllxStart ? el.dataset.prllxStart : this.defaultStart
      const prllxEnd = el.dataset.prllxEnd ? el.dataset.prllxEnd : this.defaultEnd

      el.scrollTrigger = ScrollTrigger.create({
        trigger: parentNode,
        start: prllxStart,
        end: prllxEnd,
        onUpdate: self => {
          if (el.tl) el.tl.progress(self.progress)
        }
      })

      el.tl = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1,
          ease: 'none'
        }
      })

      const from = JSON.parse(el.dataset.prllxfrom)
      const to = JSON.parse(el.dataset.prllxto)

      el.tl.fromTo(el, from, to)

    })

  }

  destroy() {
    this.DOM.elems.forEach((el) => {
      if (viewStorage.current.contains(el)) {
        el.tl.kill(true)
        el.scrollTrigger.kill(false)
      }
    })

    viewStorage.hasPrllx = false

  }
}
