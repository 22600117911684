import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import { pageIntro } from '../animations/page-intro'
import PopVideos from '../modules/pop-videos'
import MobileSwiper from '../modules/mobile-swiper'
import ProductPresentation from '../modules/product-presentation'
import ProductExplanations from '../modules/product-explanations'

export default class SingleProduct extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      pageHero: viewStorage.current.querySelector('.PageHeroProduct'),
      pageHeroVideoButtons: viewStorage.current.querySelectorAll('.PageHeroProduct .product-video-button'),
      productWebGL: viewStorage.current.querySelector('.ProductWebGL'),
      productExplanationsSection: viewStorage.current.querySelector('.ProductExplanations'),
      relatedPublicationsSwiper: viewStorage.current.querySelector('.RelatedPublications .swiper.--mobile')
    }

    console.log(viewStorage.current)

    this.init()
  }

  init() {
    const { 
      pageHero = null,
      pageHeroVideoButtons = null,
      productWebGL = null,
      productExplanationsSection = null,
      relatedPublicationsSwiper = null
    } = this.DOM

    if (pageHero && pageHeroVideoButtons && pageHeroVideoButtons.length) this.pageHeroVideoModule = new PopVideos(pageHero, pageHeroVideoButtons)
    if (productWebGL) this.productPresentationModule = new ProductPresentation(productWebGL)
    if (productExplanationsSection) this.productExplanationsModule = new ProductExplanations(productExplanationsSection)
    if (relatedPublicationsSwiper) this.relatedPublicationsSwiperModule = new MobileSwiper(relatedPublicationsSwiper)
  }

  onLeaveCompleted() {
    this.pageHeroVideoModule && this.pageHeroVideoModule.destroy()
    this.productPresentationModule && this.productPresentationModule.destroy()
    this.productExplanationsModule && this.productExplanationsModule.destroy()
    this.relatedPublicationsSwiperModule && this.relatedPublicationsSwiperModule.destroy()
  }
}
