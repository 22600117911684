import { isDEVMODE, domStorage, viewStorage } from '../_globals'

export default class Form {
  constructor(formSelector) {
    this.DOM = { form: formSelector }
    this.DOM.inputs = this.DOM.form.querySelectorAll('input, textarea')
    this.DOM.fields = this.DOM.form.querySelectorAll('input, select, textarea')
    this.DOM.resultText = this.DOM.form.querySelector('.Form__output p')
    this.DOM.submitBtn = this.DOM.form.querySelector('button[type=submit]')
    this.DOM.formFiles = this.DOM.form.querySelectorAll('.FormFile input')

    this.formURL = this.DOM.form.dataset.ajaxurl

    this.addEvents()
  }

  addEvents() {
    const {
      form = null,
      formFiles = null
    } = this.DOM

    this.onSubmit = this.onSubmit.bind(this)
    this.onUploadFile = this.onUploadFile.bind(this)
    this.removeFile = this.removeFile.bind(this)

    form && form.addEventListener('submit', this.onSubmit)

    formFiles && formFiles.length && formFiles.forEach(input => input.addEventListener('change', this.onUploadFile))
  }

  onUploadFile(e) {
    const parent = e.currentTarget.parentNode.parentNode
    const res = parent.querySelector('.res')
    const resText = res.querySelector('.res__text')
    const removeButton = res.querySelector('.res__button')

    res.classList.add('--active')
    resText.textContent = e.currentTarget.files[0].name

    removeButton.addEventListener('click', this.removeFile)
  }

  removeFile(e) {
    e && e.preventDefault()

    const res = e.currentTarget.parentNode
    const resText = res.querySelector('.res__text')
    const input = res.parentNode.querySelector('input')

    resText.textContent = ''
    res.classList.remove('--active')
    input.value = ''
  }

  onSubmit(e) {
    e.preventDefault()

    const { body } = domStorage
    const { viewScroll } = viewStorage
    const { fields, submitBtn } = this.DOM

    body.classList.add('--loading')

    submitBtn.disabled = true

    /* Remove previous errors */
    this.resetErrors()

    /* Add values to formData */
    const formData = this.getData()

    const xhr = new XMLHttpRequest()
    xhr.open('POST', this.formURL, true)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {

        switch (xhr.status) {
          case 200:
            this.onSuccess(xhr.responseText)
            break
          case 422:
            this.onInvalid(xhr.responseText)
            break
          default:
            this.onServerError(xhr.responseText)
        }

        submitBtn.disabled = false

        fields.forEach(field => {
          field.disabled = false
        })

        body.classList.remove('--loading')
      }
    }

    xhr.send(formData)
  }

  resetErrors() {
    const { form, resultText } = this.DOM

    resultText.innerHTML = ''
    resultText.classList.remove('--error')

    const errors = form.querySelectorAll('span.--error')
    errors.forEach((err) => {
      err.parentNode.classList.remove('--error')
      err.parentNode.removeChild(err)
    })

  }

  getData() {
    const { fields } = this.DOM

    const formData = new FormData()
    fields.forEach(field => {
      field.disabled = true
      if (field.type === 'radio' || field.type === 'checkbox') {
        if (field.checked) formData.append(field.name, field.value)
      } else if (field.type === 'file') {
        if (field.multiple) {
          const ins = field.files.length;
          for (let i = 0; i < ins; i++) {
            formData.append(field.name[i], field.files[i]);
          }
        } else {
          formData.append(field.name, field.files[0]);
        }
      } else {
        formData.append(field.name, field.value)
      }
    })

    return formData
  }

  onSuccess(text) {
    const { form, resultText } = this.DOM

    resultText.innerHTML = text
    form.reset()
  }

  onInvalid(text) {
    const { form } = this.DOM
    const json = JSON.parse(text)

    for (const fieldName of Object.keys(json)) {
      const field = form.querySelector(`[name=${fieldName}]`)
      if (field) {
        const err = document.createElement('span')
        err.innerHTML = json[fieldName].join('. ')
        err.classList.add('--error')
        field.parentNode.appendChild(err)
        field.parentNode.classList.add('--error')
      }
    }
  }

  onServerError(text) {
    const { resultText } = this.DOM

    resultText.innerHTML = text
    resultText.classList.add('--error')
  }

  destroy() {
    const {
      form = null,
      formFiles = null
    } = this.DOM

    form && form.removeEventListener('submit', this.onSubmit)

    formFiles && formFiles.length && formFiles.forEach(input => input.removeEventListener('change', this.onUploadFile))
  }
}
