import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'
import Marquee from '../modules/marquee'

export default class PageInvestors extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      newsletterInvestorsForm: viewStorage.current.querySelector('.Newsletter .NewsletterForm.--investors'),
      partnersMarquees: viewStorage.current.querySelectorAll('.PartnersMarquee')
    }

    this.init()
  }

  init() {
    const { 
      newsletterInvestorsForm = null,
      partnersMarquees = null
    } = this.DOM

    if (newsletterInvestorsForm) this.newsletterInvestorsFormModule = new Form(newsletterInvestorsForm)
    if (partnersMarquees && partnersMarquees.length) {
      this.marquees = []
      partnersMarquees.forEach(item => this.marquees.push(new Marquee(item)))
    }

  }

  onLeaveCompleted() {
    this.newsletterInvestorsFormModule && this.newsletterInvestorsFormModule.destroy()
    this.marquees && this.marquees.length && this.marquees.forEach(marquee => marquee.destroy())
  }
}
