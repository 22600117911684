import { domStorage } from "../_globals"
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Footer {
  constructor() {
    this.DOM = { el: domStorage.footer }

    this.setParallax()
  }

  setParallax() {
    const { el } = this.DOM

    this.tl = gsap
    .timeline({
      defaults: { ease: 'none' },
      scrollTrigger: {
        trigger: el,
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true
      }
    })
    .fromTo(el.children[0], { yPercent: -80 }, { yPercent: 0 })
  }
}