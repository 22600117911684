export default class PublicationsFilters { 
  constructor(container) {
    if (!container) return 

    this.DOM = { container }
    this.DOM.filters = this.DOM.container.querySelector('.Filters')
    this.DOM.filtersButton = this.DOM.container.querySelector('.Filters .Btn')

    if (!this.DOM.filters) return

    this.setFiltersDropdown()
  }

  setFiltersDropdown() {
    const { filtersButton } = this.DOM

    this.activeClassName = '--active'

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.closeDropdown = this.closeDropdown.bind(this)

    filtersButton.addEventListener('click', this.toggleDropdown)
  }

  toggleDropdown(e) {
    e && e.preventDefault()

    const { filters } = this.DOM

    // Toggle className
    filters.classList.toggle(this.activeClassName)

    // Adding the event on document
    setTimeout(() => document.addEventListener('click', this.closeDropdown), 50)
  }

  closeDropdown(e) {
    e && e.stopPropagation()

    const { filters } = this.DOM

    if (filters.contains(e.target)) return

    // Remove className
    filters.classList.remove(this.activeClassName)

    document.removeEventListener('click', this.closeDropdown)
  }

  destroy() {
    this.filtersButton && this.filtersButton.removeEventListener('click', this.toggleDropdown)
  }
}
