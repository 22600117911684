import { isDEVMODE, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default class Numbers {
  constructor(container) {
    if (!container) return

    this.DOM = { container }
    this.DOM.numbers = this.DOM.container.querySelectorAll('.NumberCard')
    
    if (!this.DOM.numbers.length) return

    this.DOM.clip = this.DOM.container.querySelector('.clip')

    this.settings = {
      ease: 'circ.out',
      duration: 1.5
    }

    this.setIncrementNumbers()
  }

  setIncrementNumbers() {
    const { duration, ease } = this.settings
    const { 
      clip = null,  
      numbers = null
    } = this.DOM

    const numberWithSpaces = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

    this.numbersArr = []

    numbers.forEach(number => {
      const numberText = number.querySelector('.number span:first-child')
      const numberData = numberText.getAttribute('data-number')
      const line = number.querySelector('hr')

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: clip,
          start: 'top 35%'
        }
      })

      // Animation conditions according the type of number
      if (numberData.includes('.') || numberData.includes(',')) {
        tl.to(numberText, { textContent: numberData, duration, ease, snap: { textContent: 0.1 } }, 0)
      } else {
        tl.to(numberText, { textContent: numberData, duration, ease, snap: { textContent: 1 }, onUpdate: () => numberText.innerHTML = numberWithSpaces(Math.ceil(numberText.textContent)) }, 0)
      }

      tl.to(line, { scaleX: 1, ease: 'circ.inOut' }, 0)

      this.numbersArr.push(tl)
    })
  }

  destroy() {
    this.numbersArr && this.numbersArr.length && this.numbersArr.forEach(number => number.kill())
  }
} 
