import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Form from '../modules/form'

export default class PageContact extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      contactForm: viewStorage.current.querySelector('.ContactForm.Form'),
      formSubject: viewStorage.current.querySelector('.ContactForm.Form .Form__group.--subject .Form__control')
    }

    this.init()
  }

  init() {
    const { 
      contactForm = null,
      formSubject = null
    } = this.DOM

    this.updateFormWithSubject = this.updateFormWithSubject.bind(this)

    if (contactForm) this.contactFormModule = new Form(contactForm, 'contact')
    if (formSubject) formSubject.addEventListener('change', this.updateFormWithSubject)
  }

  updateFormWithSubject(e) {
    const { contactForm = null } = this.DOM

    if (!contactForm) return

    e.currentTarget.value === 'subject_apply' ?
      contactForm.classList.add('--show-apply-fields') :
      contactForm.classList.remove('--show-apply-fields')
  }

  onLeaveCompleted() {
    this.contactFormModule && this.contactFormModule.destroy()
  }
}
