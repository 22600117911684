import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import MobileSwiper from '../modules/mobile-swiper'
import Numbers from '../modules/numbers'

export default class SingleStudy extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      numbersSection: viewStorage.current.querySelector('.Numbers'),
      relatedPublicationsSwiper: viewStorage.current.querySelector('.RelatedPublications .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const { 
      numbersSection = null,
      relatedPublicationsSwiper = null
    } = this.DOM

    if (numbersSection) this.numbersModule = new Numbers(numbersSection)
    if (relatedPublicationsSwiper) this.relatedPublicationsSwiperModule = new MobileSwiper(relatedPublicationsSwiper)
  }

  onLeaveCompleted() {
    this.numbersModule && this.numbersModule.destroy()
    this.relatedPublicationsSwiperModule && this.relatedPublicationsSwiperModule.destroy()
  }
}
