import { isDEVMODE, globalStorage, viewStorage, domStorage } from '../_globals'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import { pageIntro } from './page-intro'

export const GlobalIntro = () => {
  const { body } = domStorage
  const { viewScroll } = viewStorage
  const { current } = viewStorage

  const tl = gsap
    .timeline({
      delay: 0.2,
      defaults: { duration: 1, ease: 'power2.inOut' },
      onStart: () => {
        body.classList.remove('--preloading')
        body.classList.remove('--loading')
        body.classList.add('--animating')

        // Stop Scroll
        viewScroll.scroll && viewScroll.scroll.stop()
      },
      onComplete: () => {
        ScrollTrigger.refresh()
        body.classList.remove('--intro')

        // Start Scroll
        viewScroll.scroll && viewScroll.scroll.start()

        // Update variable
        globalStorage.firstLoad = false

        // Clean styles
        gsap.set(domStorage.header, { clearProps: 'opacity,yPercent' })
      }
    })
    .from(domStorage.header, { yPercent: -100, opacity: 0 }, 0)

    // Intro only for pages other than singleProduct
    // (because we start it later when the 3D model is loaded)
    if (current.getAttribute('data-taxi-view') !== 'singleProduct') tl.add(pageIntro(0), 0)

  return tl
}
