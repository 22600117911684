import gsap from 'gsap'
import Product from '../webgl/product'
import { pageIntro } from '../animations/page-intro'

export default class ProductPresentation {
  constructor(container) {
    if (!container) return

    this.DOM = { container }

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.init()
  }

  init() {
    const { container } = this.DOM

    this.mm.add(this.responsiveObj, context => { 
      const { isDesktop } = context.conditions

      if (isDesktop) {
        container.classList.add('--desktop')
        container.classList.remove('--mobile')

        this.productWebGL = new Product(container)
      } else {
        container.classList.remove('--desktop')
        container.classList.add('--mobile')

        pageIntro(0)

        this.productWebGL && this.productWebGL.destroy()
      }
    })
  }

  destroy() {
    this.productWebGL && this.productWebGL.destroy()
  }
}