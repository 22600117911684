import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import Marquee from '../modules/marquee'
import MobileSwiper from '../modules/mobile-swiper'
import ProductOverlap from '../modules/products-overlap'

export default class PageHome extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      productsOverlaySection: viewStorage.current.querySelector('.ProductsOverlap'),
      partnersMarquees: viewStorage.current.querySelectorAll('.PartnersMarquee'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const { 
      productsOverlaySection = null,
      partnersMarquees = null,
      relatedPostsSwiper = null
    } = this.DOM

    if (productsOverlaySection) this.productsOverlapModule = new ProductOverlap(productsOverlaySection)

    if (partnersMarquees && partnersMarquees.length) {
      this.marquees = []
      partnersMarquees.forEach(item => this.marquees.push(new Marquee(item)))
    }
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new MobileSwiper(relatedPostsSwiper)
  }

  onLeaveCompleted() {
    this.productsOverlapModule && this.productsOverlapModule.destroy()
    this.marquees && this.marquees.length && this.marquees.forEach(marquee => marquee.destroy())
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
