import './routing'

/*
Load Plugins / Functions
-------------------------------------------------- */
import { isDEVMODE, globalStorage, domStorage, viewStorage } from './_globals'
import { onReady } from './_events'
import { onSniff } from './modules/sniffer'
import { useDarkmode } from './modules/darkmode'
import Header from './modules/header'
import Footer from './modules/footer'
import WknHelperGrid from './modules/wkn-helper-grid'

class App {

  constructor() {

    // Signature Wokine
    console.log('%cCreated by Wokine, with' + '%c ❤', 'color: #FFF padding: 8px 3px 8px 10px background:#000 border-radius:3px 0px 0px 3px', 'color: #71d1c2 padding: 8px 10px 8px 3px background:#000 border-radius: 0px 3px 3px 0px')
    console.log('%c⚡' + '%c wokine.com ' + '%c⚡', 'color: #000 padding: 8px padding-right: 2px background:#71d1c2 border-radius:3px 0px 0px 3px', 'color: #FFF padding: 8px 2px background:#71d1c2', 'color: #000 padding: 8px padding-left:2px background:#71d1c2 border-radius:0px 3px 3px 0px')

    this.init()
    this.addEvents()

  }

  init() {

    // Sniffer
    onSniff()

    // Darkmode
    // useDarkmode()

    // OutdatedBrowser
    if (globalStorage.isIE && globalStorage.browserVersion <= 10) {
      domStorage.body.classList.remove('--preloading')
      domStorage.body.classList.remove('--loading')
      domStorage.outdated.classList.add('show')
      return
    }

    // Chrome Scroll Manual Reset
    domStorage.body.scrollTop = globalStorage.pageScrollTop
    document.documentElement.scrollTop = globalStorage.pageScrollTop
    history.scrollRestoration = 'manual'

    // Create Header/Footer...
    globalStorage.Header = new Header()
    // globalStorage.Footer = new Footer()

    // Helper Grid
    isDEVMODE && (globalStorage.WknHelperGrid = new WknHelperGrid())

  }

  addEvents() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      onReady()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }
}

window.app = new App()
