import { isDEVMODE, domStorage, viewStorage, globalStorage } from '../_globals'
import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { pageIntro } from '../animations/page-intro'
gsap.registerPlugin(ScrollTrigger)

// For Job links, we nned to create a variable to adapt the subject in Contact page
let jobApplicationSubject = false
let buttonDataValue = null
// For Investors links, we nned to create a variable to adapt the subject in Contact page
let investorsSubject = false

export default class PageTransitionDefault extends Transition {
  onEnter({ to, trigger, done }) {

    if (to.getAttribute('data-taxi-view') === 'pageContact') {
      const contactForm = to.querySelector('.Form.ContactForm') 
      const subjectField = contactForm.querySelector('.Form__group.--subject .Form__control')
      const subjectApplyType = contactForm.querySelector('.Form__group.--apply-type .Form__control')
      
      if (jobApplicationSubject) {
        contactForm.classList.add('--show-apply-fields')
        subjectField.value = 'subject_apply'
        if (buttonDataValue) subjectApplyType.value = buttonDataValue
      } else if (investorsSubject) {
        subjectField.value = 'subject_investors'
      }
    }

    jobApplicationSubject = false
    investorsSubject = false

    // GSAP Timeline
    const tl = gsap
      .timeline({
        delay: 0.2,
        defaults: {  ease: 'power3.inOut', duration: 1 },
        onStart: () => {
          // Updating the ScrollTriggers
          ScrollTrigger.refresh()
        },
        onComplete: () => {
          // Display the new page
          done()

          // Removing body className while animating
          domStorage.body.classList.remove('--animating')

          // Reset scroll
          setTimeout(() => window.scrollTo(globalStorage.pageScrollTop, globalStorage.pageScrollTop), 100)

          gsap.set(domStorage.footer, { clearProps: 'opacity' })
        },
      })
      
      // Intro only for pages other than singleProduct
      // (because we start it later when the 3D model is loaded)
      if (to.getAttribute('data-taxi-view') !== 'singleProduct') tl.add(pageIntro())

      tl.to(domStorage.footer, { opacity: 1 }, 0)

    return tl
  }

  onLeave({ from, trigger, done }) {
    // Update the jobApplicationSubject if the trigger (link/button) contains a className
    if (trigger && trigger !== 'popstate' && trigger.classList.contains('--job-application')) {
      if (trigger.dataset.value) buttonDataValue = trigger.dataset.value
      jobApplicationSubject = true
    }
    // Update the investorSubject if the trigger (link/button) contains a className
    if (trigger && trigger !== 'popstate' && trigger.classList.contains('--investors')) investorsSubject = true

    // GSAP Timeline
    const tl = gsap
      .timeline({
        defaults: { ease: 'power3.inOut', duration: 1 },
        onStart: () => {
          window.scrollTo(0, 0)
        },
        onComplete: () => { 
          // Remove the last page
          done()
        }
      })
      .to(from, { opacity: 0, y: -20 }, 0)
      .to(domStorage.footer, { opacity: 0 }, 0)
    
    return tl
  }
}
