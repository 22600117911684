import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WysiwygWithVideos from '../modules/wysiwyg-with-videos'
import MobileSwiper from '../modules/mobile-swiper'

export default class SinglePost extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      content: viewStorage.current.querySelector('.wswyg--content'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper.--mobile')
    }

    this.init()
  }

  init() {
    const { 
      content = null,
      relatedPostsSwiper = null 
    } = this.DOM

    if (content) this.wysiwygModule = new WysiwygWithVideos(content)
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new MobileSwiper(relatedPostsSwiper)
  }

  onLeaveCompleted() {
    this.wysiwygModule && this.wysiwygModule.destroy()
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
