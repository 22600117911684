import Plyr from 'plyr'

export default class WysiwygWithVideos {
  constructor(container) {
    this.DOM = { container }

    this.DOM.iframes = this.DOM.container.querySelectorAll('iframe[title="YouTube video player"]')

    if (!this.DOM.iframes && !this.DOM.iframes.length) return
    
    this.plyrs = []

    this.setPlayers()
  }

  setPlayers() {
    const { iframes } = this.DOM

    iframes.forEach((iframe, index) => {
      const parent = iframe.parentNode

      const plyrContainer = document.createElement('div')
      plyrContainer.className = 'plyr__container'
      const plyrTemplate = `
        <div class="plyr__video-embed" id="plyr__${index}">
          <iframe
            src="${iframe.getAttribute('src')}"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          >
          </iframe>
        </div>
      `
      plyrContainer.innerHTML = plyrTemplate

      // iframe.parentNode.insertAdjacentHTML('beforeend', plyrTemplate)
      // iframe.parentNode.removeChild(iframe)
      iframe.parentNode.replaceChild(plyrContainer, iframe)

      const plyr = new Plyr(`#plyr__${index}`, {
        controls: ['play-large', 'play', 'progress', 'mute', 'fullscreen'],
        youtube: { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1 }
      })

      this.plyrs.push(plyr)
    })
  }

  destroy() {
    const { plyrs } = this

    if (!plyrs) return

    if (plyrs.length) plyrs.forEach(plyr => plyr.destroy())
  }
}
