import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

/**
 * @param {object} Options
 */
export default class Timeline {
  constructor(options = {}) {
    if (!options) return

    this.type = options.type
    this.section = options.section 
    this.steps = options.steps
    this.mesh = options.mesh

    if (!this.section && this.steps && this.steps.length && this.mesh) return

    // General Timeline
    this.tl = gsap
      .timeline({
        defaults: { ease: 'none', duration: 1 },
        scrollTrigger: {
          trigger: this.section,
          start: 'top 20%',
          end: 'bottom bottom',
          scrub: true
        }
      })

    // We use different animations with general timeline for Rodin (bigger model)
    this.type === 'rodin' ? 
      this.setTimelineRodin() :
      this.setTimeline()
  }

  setTimelineRodin() {
    const {
      steps = null,
      mesh = null
    } = this

    // Step 0: up to down
    this.tl.fromTo(mesh.material, { opacity: 0 }, { opacity: 1 }, 0)
    this.tl.fromTo(mesh.position, { x: 0, y: 200, z: 0 }, { x: 0, y: 100, z: 0 }, 0)
    this.tl.add(this.stepIn(steps[0]), 0)
    this.tl.add(this.stepOut(steps[0]), 0.9)

    // Step 1: rotations
    this.tl.to(mesh.material, { opacity: 1 }, 1)
    this.tl.to(mesh.rotation, { x: `+=${Math.PI * 2}` }, 1)
    this.tl.to(mesh.position, { x: 0, y: 100, z: 0 }, 1)  
    this.tl.add(this.stepIn(steps[1]), 1)
    this.tl.add(this.stepOut(steps[1]), 1.9)

    // Step 2: 
    this.tl.to(mesh.material, { opacity: 1 }, 2)
    this.tl.to(mesh.rotation, { x: 1.5 }, 2)
    this.tl.to(mesh.scale, { x: 0.15, y: 0.15, z: 0.15 }, 2)
    this.tl.to(mesh.position, { x: 250, y: 100, z: 0 }, 2)
    this.tl.add(this.stepIn(steps[2]), 2)
    this.tl.add(this.stepOut(steps[2]), 2.9)


    // Step 3:
    this.tl.to(mesh.material, { opacity: 1 }, 3)
    this.tl.to(mesh.rotation, { x: 0 }, 3)
    this.tl.to(mesh.position, { x: 0, y: 100, z: 0 }, 3)
    this.tl.to(mesh.scale, { x: 0.15, y: 0.15, z: 0.15 }, 3)
    this.tl.add(this.stepIn(steps[3]), 3)
    this.tl.add(this.stepOut(steps[3]), 3.9)

    // Step 4:
    this.tl.to(mesh.material, { opacity: 1 }, 4)
    this.tl.to(mesh.rotation, { x: Math.PI / 2, y: 0, z: 0 }, 4)
    this.tl.to(mesh.position, { x: 0, y: 150, z: 0 }, 4)
    this.tl.to(mesh.scale, { x: 0.2, y: 0.2, z: 0.2 }, 4)
    this.tl.add(this.stepIn(steps[4]), 4)
    this.tl.add(this.stepOut(steps[4]), 4.9)

    // Step 5:
    this.tl.to(mesh.material, { opacity: 1 }, 5)
    this.tl.to(mesh.rotation, { x: Math.PI / 2, y: 0, z: 0 }, 5)
    this.tl.to(mesh.position, { x: 0, y: 150, z: 0 }, 5)
    this.tl.to(mesh.scale, { x: 0.3, y: 0.3, z: 0.3 }, 5)
    this.tl.add(this.stepIn(steps[5]), 5)
    this.tl.add(this.stepOut(steps[5]), 5.9)

    // Step 6:
    this.tl.to(mesh.material, { opacity: 1 }, 6)
    this.tl.to(mesh.rotation, { x: 0, y: 0, z: 0 }, 6)
    this.tl.to(mesh.position, { x: 0, y: 100, z: 0 }, 6)
    this.tl.to(mesh.scale, { x: 0.2, y: 0.2, z: 0.2 }, 6)
    this.tl.add(this.stepIn(steps[6]), 6)
    this.tl.add(this.stepOut(steps[6]), 6.9)

    // Step 7: Disappear
    this.tl.to(mesh.material, { opacity: 0 }, 7)
    this.tl.to(mesh.rotation, { y: -Math.PI * 2 }, 7)
    this.tl.to(mesh.position, { x: 0, y: 100, z: 0 }, 7)
    this.tl.to(mesh.scale, { x: 0.05, y: 0.05, z: 0.05 }, 7)

    return this.tl
  }

  setTimeline() {
    const {
      steps = null,
      mesh = null
    } = this

    // Step 0: up to down
    this.tl.fromTo(mesh.material, { opacity: 0 }, { opacity: 1 }, 0)
    this.tl.fromTo(mesh.position, { x: 0, y: 200, z: 0 }, { x: 0, y: 50, z: 0 }, 0)
    this.tl.add(this.stepIn(steps[0]), 0)
    this.tl.add(this.stepOut(steps[0]), 0.9)

    // Step 1: rotations
    this.tl.to(mesh.material, { opacity: 1 }, 1)
    this.tl.to(mesh.rotation, { x: 0, y: `+=${Math.PI * 2}`, z: 0 }, 1)
    this.tl.to(mesh.position, { x: 0, y: 0, z: 0 }, 1)  
    this.tl.add(this.stepIn(steps[1]), 1)
    this.tl.add(this.stepOut(steps[1]), 1.9)

    // Step 2: 
    this.tl.to(mesh.material, { opacity: 1 }, 2)
    this.tl.to(mesh.rotation, { x: 0.5, y: 2.35, z: -0.35 }, 2)
    this.tl.to(mesh.position, { x: 250, y: 150, z: 0 }, 2)
    this.tl.add(this.stepIn(steps[2]), 2)
    this.tl.add(this.stepOut(steps[2]), 2.9)


    // Step 3:
    this.tl.to(mesh.material, { opacity: 1 }, 3)
    this.tl.to(mesh.rotation, { x: 0, y: 0, z: 0 }, 3)
    this.tl.to(mesh.position, { x: 0, y: 0, z: 0 }, 3)
    this.tl.add(this.stepIn(steps[3]), 3)
    this.tl.add(this.stepOut(steps[3]), 3.9)

    // Step 4:
    this.tl.to(mesh.material, { opacity: 1 }, 4)
    this.tl.to(mesh.rotation, { x: Math.PI / 2, y: 0, z: 0 }, 4)
    this.tl.to(mesh.position, { x: 0, y: 150, z: 0 }, 4)
    this.tl.to(mesh.scale, { x: 1, y: 1, z: 1 }, 4)
    this.tl.add(this.stepIn(steps[4]), 4)
    this.tl.add(this.stepOut(steps[4]), 4.9)

    // Step 5:
    this.tl.to(mesh.material, { opacity: 1 }, 5)
    this.tl.to(mesh.rotation, { x: Math.PI / 2, y: 0, z: 0 }, 5)
    this.tl.to(mesh.position, { x: 0, y: 150, z: 0 }, 5)
    this.tl.to(mesh.scale, { x: 1.25, y: 1.25, z: 1.25 }, 5)
    this.tl.add(this.stepIn(steps[5]), 5)
    this.tl.add(this.stepOut(steps[5]), 5.9)

    // Step 6:
    this.tl.to(mesh.material, { opacity: 1 }, 6)
    this.tl.to(mesh.rotation, { x: 0, y: 0, z: 0 }, 6)
    this.tl.to(mesh.position, { x: 0, y: 0, z: 0 }, 6)
    this.tl.to(mesh.scale, { x: 1, y: 1, z: 1 }, 6)
    this.tl.add(this.stepIn(steps[6]), 6)
    this.tl.add(this.stepOut(steps[6]), 6.9)

    // Step 7: Disappear
    this.tl.to(mesh.material, { opacity: 0 }, 7)
    this.tl.to(mesh.rotation, { x: 0, y: -Math.PI * 2, z: 0 }, 7)
    this.tl.to(mesh.position, { x: 0, y: 0, z: 0 }, 7)
    this.tl.to(mesh.scale, { x: 0.75, y: 0.75, z: 0.75 }, 7)

    return this.tl
  }

  stepIn(step) {
    if (!step) return

    return gsap.fromTo(step.querySelector('.wrapper'), { opacity: 0,  y: (index, target, targets) => target.parentNode.classList.contains('--card') ? 100 : 20 }, {  opacity: 1, y: 0, ease: 'power3.inOut', duration: 0.5 })
  }

  stepOut(step) {
    if (!step) return

    return gsap.to(step.querySelector('.wrapper'), { opacity: 0, y: (index, target, targets) => target.parentNode.classList.contains('--card') ? -100 : 0, ease: 'power3.inOut', duration: 0.5 }, 0)  
  }

  destroy() {
    this.tl && this.tl.kill()
    this.steps && this.steps.length && this.steps.forEach(step => gsap.set(step.querySelector('.wrapper'), { clearProps: 'all' }))
  }
}
