import { domStorage } from '../_globals'
import { pageIntro } from '../animations/page-intro'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import Timeline from './timeline'

/**
 * All the params we need to have the correct animation:
 * @param {node} container - DOM section (ex: <section class="ProductWebGL">)
 */
export default class Product {
  constructor(container) {
    if (!container) return
    
    this.DOM = { container }
    this.DOM.canvas = this.DOM.container.querySelector('canvas.webgl')
    this.DOM.steps = this.DOM.container.querySelectorAll('.ProductPresentationStep')

    if (!this.DOM.steps && !this.DOM.steps.length) return

    this.type = this.DOM.canvas.dataset.type

    this.percentage = 0 

    domStorage.percentage = document.createElement('div')
    domStorage.percentage.id = 'Percentage'
    domStorage.percentage.textContent = `${this.percentage}%`
    domStorage.body.appendChild(domStorage.percentage)

    this.sizes = {
      width: window.innerWidth,
      height: window.innerHeight
    }

    this.clock = new THREE.Clock()

    this.modelPath = this.DOM.canvas.getAttribute('data-model')

    this.setScene()
    this.setLoadingManager()
    this.setModel()
    this.setLights()
    this.setCamera()
    this.setRenderer()
    this.setResize()

    this.tick()
    
    window.addEventListener('resize', this.setResize.bind(this))
  }

  setResize() {
    // Update sizes
    this.sizes.width = window.innerWidth
    this.sizes.height = window.innerHeight

    // Update camera
    this.camera.aspect = this.sizes.width / this.sizes.height
    this.camera.updateProjectionMatrix()

    // Update renderer
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
  }

  setScene() {
    this.scene = new THREE.Scene()
    // this.scene.background = new THREE.Color('#212139')
  }

  setLoadingManager() {
    this.loadingManager = new THREE.LoadingManager()
    this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      // Loading percentage
      this.percentage = Math.round((itemsLoaded/itemsTotal) * 100)
      // Displaying the percentage to the DOM
      domStorage.percentage.textContent = `${this.percentage}%`
    }

    this.loadingManager.onLoad = () => pageIntro(0)

    this.loadingManager.onError = () => console.log('On error!')
  }

  setModel() {
    this.dracoLoader = new DRACOLoader(this.loadingManager)
    this.dracoLoader.setDecoderPath('./draco/')

    this.gltfLoader = new GLTFLoader(this.loadingManager)
    this.gltfLoader.setDRACOLoader(this.dracoLoader)

    this.modelScene = null

    this.gltfLoader.load(
      // 1. Path of the file
      this.modelPath,

      // 2. Callback functions
      // 2.1 Success
      (gltf) => {
        this.modelScene = gltf.scene

        let mesh = null

        // Get the mesh from the model
        this.modelScene.traverse(child => child instanceof THREE.Mesh && (mesh = child))

        // Adapt the material of the mesh
        mesh.material.transparent = true
        mesh.material.metalness = 0.5
        mesh.material.roughness = 0.1
        mesh.material.color = new THREE.Color('#ffffff')

        // Center model in the Scene
        if (this.type === 'rodin') mesh.geometry.center()

        // Add to the scene
        this.scene.add(this.modelScene)

        // Animations on scroll
        this.timeline = new Timeline({
          type: this.type,
          section: this.DOM.container, 
          steps: this.DOM.steps, 
          mesh: mesh
        })
      },
      // 2.2. Progress
      () => { /*console.log('model progress')*/ },
      // 2.3. Error
      (err) => { console.log('model error', err) }
    )
  }

  setLights() {
    this.ambientLight = new THREE.AmbientLight('#ffffff', 3)
    // this.ambientLight.position.set(0, 1.5, 5)
    this.scene.add(this.ambientLight)

    this.directionalLight = new THREE.DirectionalLight('#ffffff', 3)
    this.directionalLight.position.set(1, 1, 1)
    this.scene.add(this.directionalLight)
  }

  setCamera() {
    // Base camera
    this.camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100)
    this.camera.position.set(0, 1, 4)
    this.scene.add(this.camera)

    // Controls
    // this.controls = new OrbitControls(this.camera, this.DOM.canvas)
    // this.controls.enableDamping = true
    // this.controls.enableZoom = true
    // this.controls.enablePan = true
  }

  setRenderer() {
    this.renderer = new THREE.WebGLRenderer({
        canvas: this.DOM.canvas,
        antialias: true,
        alpha: true
    })
    this.renderer.setSize(this.sizes.width, this.sizes.height)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
}

  tick() {
    const elapsedTime = this.clock.getElapsedTime()

    // Update controls
    this.controls && this.controls.update()

    // Render
    this.renderer.render(this.scene, this.camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(this.tick.bind(this))
}

  destroy() {
    this.timeline && this.timeline.destroy()

    window.cancelAnimationFrame(this.tick.bind(this))

    this.scene && this.scene.remove(this.scene, this.scene.children)
    this.renderer && this.renderer.dispose()
  }
}
