import {Renderer} from '@unseenco/taxi';
import {isDEVMODE, viewStorage} from '../_globals'
import PublicationsFilters from '../modules/publications-filters'
import MobileSwiper from '../modules/mobile-swiper'

export default class ArchivePublications extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      allPublicationsSection: viewStorage.current.querySelector('.AllPublications'),
      relatedPostsSwiper: viewStorage.current.querySelector('.RelatedPosts .swiper.--mobile'),
      formFilters: viewStorage.current.querySelector('.Filters'),
    }

    this.init()
    this.initForm()
  }

  initForm() {
    const allTypeCheckboxes = this.DOM.formFilters.querySelectorAll('input[type=checkbox]:not([name=filters_publications_all])');
    const allPublicationFilterCheckbox = this.DOM.formFilters.querySelector('#filters_publications_all');

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const types = urlParams.getAll('types[]')

    for (let checkbox of allTypeCheckboxes) {
      for (const type of types) {
        if (checkbox.value === type) {
          checkbox.checked = true
          allPublicationFilterCheckbox.checked = false
        }
      }
      checkbox.addEventListener('change', () => {
        this.onFormChange();
      })
    }

    allPublicationFilterCheckbox.addEventListener('change', () => {
      this.onCheckAll()
    })
  }

  init() {
    const {
      allPublicationsSection = null,
      relatedPostsSwiper = null
    } = this.DOM

    if (allPublicationsSection) this.publicationsFiltersModule = new PublicationsFilters(allPublicationsSection)
    if (relatedPostsSwiper) this.relatedPostsSwiperModule = new MobileSwiper(relatedPostsSwiper)
  }

  onCheckAll() {

    clearTimeout(this.timerDebounce);

    this.timerDebounce = setTimeout(() => {
      window.location = `?`

    }, 700);
  }

  onFormChange() {

    const allPublicationFilterCheckbox = this.DOM.formFilters.querySelector('#filters_publications_all');
    allPublicationFilterCheckbox.checked = false

    clearTimeout(this.timerDebounce);

    this.timerDebounce = setTimeout(() => {

      const checkedTypes = viewStorage.current.querySelectorAll('input[type=checkbox]:not([name=filters_publications_all]):checked')
      const types = [...checkedTypes].map((input) => input.value);

      let queryString = '';

      for (let type of types) {
        queryString += `&types[]=${encodeURIComponent(type)}`;
      }

      window.location = `?${queryString}`

    }, 700);
  }

  onLeaveCompleted() {
    this.publicationsFiltersModule && this.publicationsFiltersModule.destroy()
    this.relatedPostsSwiperModule && this.relatedPostsSwiperModule.destroy()
  }
}
