import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
import MobileSwiper from './mobile-swiper'

export default class ProductExplanations{
  constructor(container){ 
    if (!container) return

    this.DOM = { container }
    this.DOM.swiper = this.DOM.container.querySelector('.swiper.--mobile')
    this.DOM.swiperWrapper = this.DOM.container.querySelector('.swiper-wrapper')
    this.DOM.swiperSlides = this.DOM.container.querySelectorAll('.swiper-slide')

    // MatchMedia variable
    this.mm = gsap.matchMedia()
    // Breakpoint
    this.breakpoint = 768
    // Responsive object
    this.responsiveObj = {
      isDesktop: `(min-width: ${this.breakpoint}px)`,
      isMobile: `(max-width: ${this.breakpoint - 1}px)`
    }

    this.mobileSwiper = new MobileSwiper(this.DOM.swiper)

    this.setHorizontalScroll()
  }

  setHorizontalScroll() {
    const { container, swiper, swiperWrapper, swiperSlides } = this.DOM

    this.mm.add(this.responsiveObj, (context) => {
      const { isDesktop } = context.conditions

      const x =  -(swiperWrapper.offsetWidth - window.innerWidth + 100)

      if (isDesktop) {
        this.tl = gsap
          .timeline({
            defaults: { ease: 'none' },
            scrollTrigger: {
              trigger: container.querySelector('.container'),
              pin: true,
              scrub: true
            }
          })
          .to(swiperWrapper, { x }, 0)
      } else {
        this.tl && this.tl.kill()
      }
    })
  }

  destroy() {
    this.mobileSwiper && this.mobileSwiper.destroy()
  }
}
