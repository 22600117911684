import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import PopVideos from '../modules/pop-videos'
import WknPlyr from '../modules/wkn-plyr'
import MobileSwiper from '../modules/mobile-swiper'

export default class PageAboutUs extends Renderer {
  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      ourTeam: viewStorage.current.querySelector('.OurTeam'),
      ourTeamCards: viewStorage.current.querySelectorAll('.OurTeam .EmployeeCard'),
      valuesSwiper: viewStorage.current.querySelector('.Values .swiper.--mobile'),
      video: viewStorage.current.querySelector('.Video')
    }

    this.init()
  }

  init() {
    const { 
      ourTeam = null,
      ourTeamCards = null,
      video = null,
      valuesSwiper = null
    } = this.DOM

    if (ourTeam && ourTeamCards && ourTeamCards.length) this.employeesVideosModule = new PopVideos(ourTeam, ourTeamCards)
    if (video) this.videoPlyrModule = new WknPlyr(video)
    if (valuesSwiper) this.valuesSwiperModule = new MobileSwiper(valuesSwiper)

  }

  onLeaveCompleted() {  
    this.employeesVideosModule && this.employeesVideosModule.destroy()
    this.videoPlyrModule && this.videoPlyrModule.destroy()
    this.valuesSwiperModule && this.valuesSwiperModule.destroy()
  }
}
