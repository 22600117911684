import { isDEVMODE, globalStorage, domStorage } from '../_globals'

export default class NavSubmenus {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.navItemsButtons = this.DOM.el.querySelectorAll('.NavItem.--submenu button')

    if (!this.DOM.navItemsButtons && !this.DOM.navItemsButtons.length) return

    this.currentSubmenu = null

    this.className = '--show-submenu'

    this.openSubmenu = this.openSubmenu.bind(this)
    this.closeSubmenu = this.closeSubmenu.bind(this)

    this.DOM.navItemsButtons.forEach(button => button.addEventListener('click', this.openSubmenu))

    globalStorage.closeAllSubmenus = () => this.closeAllSubmenus()
  }

  openSubmenu(e) {
    e && e.preventDefault()

    const currentButton = e.currentTarget
    const currentNavItem = currentButton.parentNode

    this.currentSubmenu && this.currentSubmenu.classList.remove(this.className)

    // Update the current submenu variable
    this.currentSubmenu = currentNavItem

    // Add className for the current submenu
    this.currentSubmenu.classList.add(this.className)

    // Add the event on document for click outside
    setTimeout(() => document.addEventListener('click', this.closeSubmenu), 50)
  }

  closeSubmenu(e) {
    if (!this.currentSubmenu) return

    e && e.stopPropagation()

    if (this.currentSubmenu.contains(e.target)) return

    // Remove className for the current submenu
    this.currentSubmenu.classList.remove(this.className)

    // Adapt the currentSubmenu variable
    this.currentSubmenu = null
    
    // Remove the event on document
    document.removeEventListener('click', this.closeSubmenu)
  }

  closeAllSubmenus() {
    if (!this.currentSubmenu) return

    this.currentSubmenu.classList.remove(this.className)
    
    this.currentSubmenu = null
  }
}
