import {isDEVMODE, viewStorage} from '../_globals';
import ENoticeDownload from '../popups/e-notice-download';
import ENoticeLetter from '../popups/e-notice-letter';
import {Renderer} from "@unseenco/taxi";
import { func } from 'three/addons/nodes/code/FunctionNode'

export default class PageENotice extends Renderer {
  initialVersions = [];

  initialLoad() {
    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
      this.onEnter();
    };

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }

  onEnter() {
    this.DOM = {
      pageHero: viewStorage.current.querySelector('.PageHero'),
      sectionProducts: viewStorage.current.querySelector('.ENoticeProducts'),
      popupDownload: viewStorage.current.querySelector('.Popup.--e-notice-download'),
      letterPopup: viewStorage.current.querySelector('.Popup.--e-notice-letter'),
      ENoticeSelectProduct: viewStorage.current.querySelector('#e_notice_letter-products'),
      ENoticeSelectDocument: viewStorage.current.querySelector('#e_notice_letter-documents'),
      ENoticeSelectVersion: viewStorage.current.querySelector('#e_notice_letter-version'),
    };

    if (this.DOM.pageHero) {
      this.letterPopup = new ENoticeLetter({
        section: this.DOM.pageHero,
        popup: this.DOM.letterPopup,
      });
    }

    if (this.DOM.sectionProducts) {
      this.updateDocumentSelect();
      this.downloadPopup = new ENoticeDownload({
        section: this.DOM.sectionProducts,
        popup: this.DOM.popupDownload,
      });
    }

    this.DOM.ENoticeSelectProduct.addEventListener('change', () => {
      this.updateDocumentSelect();
    });
    this.DOM.ENoticeSelectDocument.addEventListener('change', () => {
      this.updateVersionSelect();
    })
  }

  updateDocumentSelect() {

    let productsData = JSON.parse(document.querySelector('.ENotice').getAttribute('data-notice-products'));
    console.log('noticeProductsData', productsData);
    const selectProductValue = this.DOM.ENoticeSelectProduct.value;
    const selectedProduct = productsData.find(product => {
      const slug = product.slug;
      return slug === selectProductValue;
    });
    console.log('selectedProduct', selectedProduct);
    if (!selectedProduct) {
      console.error('No product selected');
      return;
    }
    const productDocuments = selectedProduct.documents;
    // Update the document select with the new documents
    let documentSelect = document.querySelector('#e_notice_letter-documents');
    documentSelect.innerHTML = '';
    if (!productDocuments) {
      console.error('No product documents');
      return;
    }
    productDocuments.forEach(productDocument => {
      let option = document.createElement('option');
      option.text = productDocument.title;
      option.value = productDocument.title.toLowerCase().replace(/ /g, '_');
      documentSelect.add(option);
    });
    console.log('documentSelect', documentSelect);
    this.updateVersionSelect();
  }

  onLeaveCompleted() {
    this.letterPopup && this.letterPopup.destroy();
    this.downloadPopup && this.downloadPopup.destroy();
  }

  updateVersionSelect () {
    console.log('updateVersionSelect');
    let productsData = JSON.parse(document.querySelector('.ENotice').getAttribute('data-notice-products'));
    const selectProductValue = this.DOM.ENoticeSelectProduct.value;
    const selectedProduct = productsData.find(product => {
      const slug = product.slug;
      return slug === selectProductValue;
    });
    if (!selectedProduct) {
      console.error('No product selected');
      return;
    }
    const selectDocumentValue = this.DOM.ENoticeSelectDocument.value;
    if (!selectDocumentValue) {
      console.error('No document selected');
      return;
    }
    const selectedDocument = selectedProduct.documents.find(document => {
      const title = document.title;
      return title.toLowerCase().replace(/ /g, '_') === selectDocumentValue;
    });
    const documentVersions = selectedDocument.files;
    if (!documentVersions) {
      console.error('No document versions');
      return;
    }
    let versionSelect = document.querySelector('#e_notice_letter-version');
    versionSelect.innerHTML = '';
    documentVersions.forEach(documentVersion => {
      let option = document.createElement('option');
      option.text = documentVersion.title;
      option.value = documentVersion.title.toLowerCase().replace(/ /g, '_');
      versionSelect.add(option);
    });
  }
}
